import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured/dashboards-builder/$boardId/')({
  beforeLoad: async ({ context, params }) => {
    const dashboardsAccess = await context.isFlagEnabled(
      '@myn/secured',
      'dashboardsAccess',
    );

    if (!dashboardsAccess) {
      throw redirect({ to: '/campaigns' });
    }

    throw redirect({
      to: '/insights/dashboards/$boardId',
      params: {
        boardId: params.boardId,
      },
      replace: true,
    });
  },
});
